import './App.css'
import { Routes, Route, Navigate } from 'react-router-dom'
import Navbar from './components/Navbar'
import Products from './components/Products'
import Footer from './components/Footer'
import PalmKernelShells from './components/PalmKernelShells'
import RiceHuskPellets from './components/RiceHuskPellets'

function App() {
  return (
    <div id="App">
      <Navbar />

      <Routes>
        <Route path='/' element={<Navigate to='/products/palm-kernel-shells' />} />
        <Route path='/products'>
          <Route index element={<Products />} />
          <Route path='palm-kernel-shells' element={<PalmKernelShells />} />

          {/*
          <Route path='rice-husk-pellets' element={<RiceHuskPellets />} />
          */}
          
        </Route>
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>

      <Footer />
    </div>
  )
}

export default App
