import React from 'react'

const TitleBox = props => {
    return (
        <div className='title-box'>
            <span className='title-text'> {props.title} </span>
        </div>
    )
}

export default TitleBox
