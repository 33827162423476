import React from 'react'
import { useInView } from 'react-intersection-observer';

const GalleryCard = ({props}) => {
    const [inViewRef, inView] = useInView({
        threshold: 0.6,
        triggerOnce: true
    })

    return (
        <div ref={inViewRef} className={ inView ? 'active' : '' }>
            <img src={props.img} alt={props.title} />
            <span className='gallery-title'> {props.title} </span>
            <span className='gallery-desc'> {props.desc} </span>
        </div>
    )
}

export default GalleryCard
