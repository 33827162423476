import React from 'react'
import Gallery from './Gallery'
import ImageBox from './ImageBox'
import DescBox from './DescBox'
import TitleBox from './TitleBox'

const PalmKernelShells = () => {
    const imagebox_content = [
        require('../images/palm-field.png'),
        require('../images/palm-field.png'),
        require('../images/palm-field.png'),
        require('../images/palm-field.png')
    ]

    const gallery_content = [{
        title: 'Pure Quality',
        desc: 'Sourced directly from nature, ensuring the best burn efficiency and warmth for your industry.',
        img: require('../images/pks.png')
    },{
        title: 'Low Ash Content',
        desc: 'Premium Palm Kernel Shells, offering minimal ash residue for industry-leading performance.',
        img: require('../images/test.png')
    },{
        title: 'High Calorific',
        desc: 'Experience the power of high calorific value with our Palm Kernel Shells, boasting an impressive range of 4000 to 4500 kcal/kg.',
        img: require('../images/boiler.png')
    },{
        title: 'Eco-Friendly',
        desc: 'Sourced from renewable resources, our Palm Kernel Shells are sustainable and kind to Earth‘s future.',
        img: require('../images/eco-friendly.png')
    }]

    return (
        <div id='main'>
            <div id='title'>
                <TitleBox title='PALM KERNEL SHELLS' />
                <ImageBox content={imagebox_content} />
            </div>

            <DescBox title='A Cozy, Sustainable Future' 
            desc='Imagine a world where powering your industry isn‘t a burden on your wallet or the environment. 
            Palm Kernel Shells provide just that, with premium quality and the promise of a cleaner, greener world.' />

            <Gallery content={gallery_content} />
        </div>
    )
}

export default PalmKernelShells
