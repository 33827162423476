import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div id='footer'>
            <Link to='https://wa.me/6282190003380' target='_blank'>
                <img src={require('../icons/whatsapp-icon.png')} alt='Whatsapp Me!' />
                Whatsapp Us!
            </Link>
            <span> +62-821-9000-3380 </span>
            <span> gomasjayanusantara@gmail.com </span>
            <span> Jalan Ir. Sutami No.53, Makassar, South Sulawesi, Indonesia </span>
            <span> © PT GOMAS JAYA NUSANTARA </span>
        </div>
    )
}

export default Footer
