import React from 'react'
import { Link } from 'react-router-dom'

const Navbar = () => {
    return (
        <div id='navbar'>
            <span> PT GOMAS JAYA NUSANTARA </span>
            <Link to='/'> Home </Link>
            <Link to='/products'> Products </Link>
            <Link onClick={() => {window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' })}}> Contact </Link>
        </div>
    )
}

export default Navbar
