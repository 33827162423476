import React from 'react'
import { useNavigate } from 'react-router-dom'

const ProductCard = ({props}) => {
    let navigate = useNavigate()

    const handleClick = () => {
        navigate(`./${props.title.toLowerCase().split(' ').join('-')}`)
        window.scrollTo(0, 0)
    }

    return (
        <div className='product-card' onClick={handleClick}>
            <img src={props.img} alt={props.title} />
            <div>
                <span className='product-card-title'> {props.title} </span>
                <span className='product-card-desc'> {props.desc} </span>
            </div>
        </div>
    )
}

export default ProductCard
