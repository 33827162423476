import React from 'react'
import { useInView } from 'react-intersection-observer';

const ImageBox = ({content}) => {
    const [inViewRef, inView] = useInView({
        threshold: 0.7,
        triggerOnce: true
    })

    return (
        <div className={`image-layout ${ inView ? 'active' : '' }`} ref={inViewRef}>
            <div className='image-box'>
                {content.map((el, index) => {
                    return (
                        <img src={el} key={index} alt='images' />
                    )
                })}
            </div>
        </div>
    )
}

export default ImageBox
