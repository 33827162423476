import React from 'react'
import { useInView } from 'react-intersection-observer';

const DescBox = props => {
    const [inViewRef, inView] = useInView({
        threshold: 0.6,
        triggerOnce: true
    })

    return (
        <div className={`desc-box ${ inView ? 'active' : '' }`} ref={inViewRef}>
            <span className='desc-title'> {props.title} </span>
            <span className='desc-text'> {props.desc} </span>
        </div>
    )
}

export default DescBox
