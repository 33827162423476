import React from 'react'
import TitleBox from './TitleBox'
import ProductCard from './ProductCard'

const Products = () => {
    const content = [
        {
            title: 'PALM KERNEL SHELLS',
            desc: 'Unleash the power of eco-friendly heating! Our pellet products are here to change your life with their energy efficiency and wallet-friendly prices.',
            img: require('../images/pks.png')
        },
        /*
        {
            title: 'RICE HUSK PELLETS',
            desc: 'Unleash the power of eco-friendly heating! Our pellet products are here to change your life with their energy efficiency and wallet-friendly prices.',
            img: require('../images/rice-husk.png')
        },
        */
    ]

    return (
        <div id='main'>
            <div id='title'>
                <TitleBox title='PRODUCTS' />
            </div>
            <div className='products'>
                {content.map((el, index) => {
                    return (
                        <ProductCard props={el} key={index} />
                    )
                })}
            </div>
        </div>
    )
}

export default Products
