import React from 'react'
import GalleryCard from './GalleryCard'

const Gallery = ({content}) => {
    return (
        <div className='gallery-card'>
            {content.map((el, index) => {
                return (
                    <GalleryCard props={el} key={index} />
                )
            })}
        </div>
    )
}

export default Gallery
